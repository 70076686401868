import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { RadialGradient } from '../assets';

const useStyles = makeStyles(() => {
  return {
    headerSvgAbove: {},
    headerSvgBelow: {},
    headerSvg: {
      position: 'absolute',
      width: '100%',
      height: '6vw',
      '&$headerSvgBelow': {
        bottom: -1,
      },
      '&$headerSvgAbove': {
        top: -1,
      },
      /* set height to pixels if you want angle to change with screen width */
    },
    section: {
      paddingTop: 125,
      paddingBottom: 125,
    },
  };
});

export enum SlantAngle {
  LEFT_TO_RIGHT = 'left-to-right',
  RIGHT_TO_LEFT = 'right-to-left',
}

export enum SlantPosition {
  ABOVE = 'above',
  BELOW = 'below',
}

export enum SlantGradient {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  RADIAL = 'radial',
  WHITE = 'white',
}

const GRADIENTS = {
  [SlantGradient.HORIZONTAL]:
    'linear-gradient(-143deg, #2C2E3B 0%, #4D4E69 100%)',
  [SlantGradient.VERTICAL]: 'linear-gradient(0deg, #2C2E3B 0%, #4D4E69 100%)',
  [SlantGradient.RADIAL]: `url(${RadialGradient})`,
  WHITE: '#fff',
};

const POINTS = {
  [SlantPosition.ABOVE]: {
    [SlantAngle.LEFT_TO_RIGHT]: '0,0 100,0 100,100',
    [SlantAngle.RIGHT_TO_LEFT]: '0,0 0,100 100,0',
  },
  [SlantPosition.BELOW]: {
    [SlantAngle.LEFT_TO_RIGHT]: '0,0 0,100, 100,100',
    [SlantAngle.RIGHT_TO_LEFT]: '0,100 100,0 100,100',
  },
};

interface SlantProps {
  position: SlantPosition;
  angle: SlantAngle;
}

export const Slant: React.FC<SlantProps> = ({
  position,
  angle,
}: SlantProps) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      className={clsx(classes.headerSvg, {
        [classes.headerSvgAbove]: position === SlantPosition.ABOVE,
        [classes.headerSvgBelow]: position === SlantPosition.BELOW,
      })}
    >
      <polygon fill="white" points={POINTS[position][angle]} />
    </svg>
  );
};

interface SlantContainerProps {
  angle: SlantAngle;
  gradient: SlantGradient;
  onlyTop?: boolean;
  onlyBottom?: boolean;
  centerContents?: boolean;
}

export const SlantContainer: React.FC<SlantContainerProps> = ({
  angle,
  children,
  gradient,
  onlyTop,
  onlyBottom,
  centerContents = false,
}: React.PropsWithChildren<SlantContainerProps>) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.section}
      style={{
        backgroundImage: GRADIENTS[gradient],
        backgroundPosition: 'center',
        backgroundSize: 'auto',
        position: 'relative',
      }}
    >
      {!onlyBottom && <Slant position={SlantPosition.ABOVE} angle={angle} />}
      <Container
        style={
          centerContents
            ? {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {}
        }
      >
        {children}
      </Container>
      {!onlyTop && (
        <Slant
          position={SlantPosition.BELOW}
          angle={
            angle === SlantAngle.LEFT_TO_RIGHT
              ? SlantAngle.RIGHT_TO_LEFT
              : SlantAngle.LEFT_TO_RIGHT
          }
        />
      )}
    </Grid>
  );
};

export default SlantContainer;
